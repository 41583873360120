import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Link, Section, Strong, Em } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Vibrant Ventures
			</title>
			<meta name={"description"} content={"Your choice for memorable moments"} />
			<meta property={"og:title"} content={"Vibrant Ventures"} />
			<meta property={"og:description"} content={"Your choice for memorable moments"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65e71766009abe001f042fa9/images/3-2.jpg?v=2024-03-05T15:51:48.520Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65e71766009abe001f042fa9/images/3-2.jpg?v=2024-03-05T15:51:48.520Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65e71766009abe001f042fa9/images/3-2.jpg?v=2024-03-05T15:51:48.520Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65e71766009abe001f042fa9/images/3-2.jpg?v=2024-03-05T15:51:48.520Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65e71766009abe001f042fa9/images/3-2.jpg?v=2024-03-05T15:51:48.520Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65e71766009abe001f042fa9/images/3-2.jpg?v=2024-03-05T15:51:48.520Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65e71766009abe001f042fa9/images/3-2.jpg?v=2024-03-05T15:51:48.520Z"} />
		</Helmet>
		<Components.Header2>
			<Override slot="text">
				Vibrant Ventures
			</Override>
		</Components.Header2>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 32px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 32px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://uploads.quarkly.io/65e71766009abe001f042fa9/images/4-3.jpg?v=2024-03-05T15:51:48.525Z"
					object-fit="cover"
					width="100%"
					height="100%"
					border-radius="24px"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					min-height="600px"
					max-width="480px"
					sm-min-height="100vw"
					srcSet="https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/4-3.jpg?v=2024-03-05T15%3A51%3A48.525Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/4-3.jpg?v=2024-03-05T15%3A51%3A48.525Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/4-3.jpg?v=2024-03-05T15%3A51%3A48.525Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/4-3.jpg?v=2024-03-05T15%3A51%3A48.525Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/4-3.jpg?v=2024-03-05T15%3A51%3A48.525Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/4-3.jpg?v=2024-03-05T15%3A51%3A48.525Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/4-3.jpg?v=2024-03-05T15%3A51%3A48.525Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 0px 16px 16px"
				justify-content="center"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					About Us
				</Text>
				<Text margin="0px 0px 40px 0px" color="--greyD3" font="--lead" lg-text-align="center">
					At Vibrant Ventures, we believe in the power of moments. Our journey began with a simple idea: to create unforgettable experiences that resonate on a personal level. With a special focus on small concerts, birthdays, conferences, and more, we have dedicated ourselves to transforming ordinary gatherings into extraordinary celebrations. Our foundation is built on creativity, enthusiasm, and a pursuit of excellence, ensuring that each event is not just planned but meticulously curated.
				</Text>
				<Link
					href="/services"
					padding="12px 24px 12px 24px"
					color="--light"
					text-decoration-line="initial"
					font="--lead"
					border-radius="8px"
					margin="0px 16px 0px 0px"
					transition="background-color 0.2s ease-in-out 0s"
					sm-margin="0px 0px 16px 0px"
					sm-text-align="center"
					background="--color-primary"
					hover-transition="background-color 0.2s ease-in-out 0s"
					hover-background="--color-primary"
				>
					Our Services
				</Link>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-dark">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 0px 0px" color="--light" font="--headline2" md-margin="0px 0px 30px 0px">
					The Essence of Vibrant Ventures
				</Text>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 40px 0px" color="--light" font="--base">
					What sets Vibrant Ventures apart is not just our attention to detail but our desire to infuse each event with a unique spark. We do not merely organize – we innovate, ensuring each celebration is a true reflection of our client's vision.{" "}
				</Text>
				<Text margin="0px 0px 0px 0px" color="--light" font="--base">
					<Strong>
						Our team excels in turning dreams into reality, creating moments that are not only memorable but genuinely vibrant.
					</Strong>
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					Why Vibrant Ventures Stands Out
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					<Strong>
						Personalized Creative Approach.
						<br />
					</Strong>
					{" "}Each event is a blank canvas, and our creativity is your palette. Let us paint your perfect day with broad strokes of innovation and detail.
					<br />
					<Strong>
						Passionate Planning.
						<br />
					</Strong>
					Our team's passion for event planning ensures that we go the extra mile to make your special occasion as flawless as it is impressive.
					<br />
					<Strong>
						Authentic Experiences.
						<br />
					</Strong>
					{" "}Drawing from local culture and flair, we create events that not only make sense but are deeply connected to the essence of the occasion.
					<br />
					<Strong>
						Stress-Free Celebrations.
						<br />
					</Strong>
					{" "}Our comprehensive planning process guarantees a worry-free experience, allowing you to fully immerse in the joy of your event.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				align-self="center"
				height="100%"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://uploads.quarkly.io/65e71766009abe001f042fa9/images/2-3.jpg?v=2024-03-05T15:51:48.522Z"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
						height="100%"
						srcSet="https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/2-3.jpg?v=2024-03-05T15%3A51%3A48.522Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/2-3.jpg?v=2024-03-05T15%3A51%3A48.522Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/2-3.jpg?v=2024-03-05T15%3A51%3A48.522Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/2-3.jpg?v=2024-03-05T15%3A51%3A48.522Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/2-3.jpg?v=2024-03-05T15%3A51%3A48.522Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/2-3.jpg?v=2024-03-05T15%3A51%3A48.522Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65e71766009abe001f042fa9/images/2-3.jpg?v=2024-03-05T15%3A51%3A48.522Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" background="--color-dark">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
				padding="0px 80px 0px 0px"
				lg-padding="0px 30px 0px 0px"
				md-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 0px 0px" color="--light" font="--headline2" md-margin="0px 0px 30px 0px">
					Create Memories with Vibrant Ventures
				</Text>
			</Box>
			<Box
				width="50%"
				display="flex"
				flex-direction="column"
				justify-content="space-between"
				md-width="100%"
			>
				<Text margin="0px 0px 40px 0px" color="--light" font="--base">
					Embark on a journey with us where your event becomes more than just a date on the calendar. It becomes a story to be told, a memory etched in the hearts of all who attend.{" "}
				</Text>
				<Text margin="0px 0px 0px 0px" color="--light" font="--base">
					<Strong>
						With Vibrant Ventures, you are not just hosting an event – you are creating a legacy.
					</Strong>
				</Text>
			</Box>
		</Section>
		<Section padding="100px 0 100px 0" background="--color-light" quarkly-title="FAQ-10">
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 600 36px/1.2 --fontFamily-sans" color="--darkL1">
						Frequently Asked Questions
					</Text>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="block"
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="50px 50px"
					md-grid-template-columns="1fr"
					sm-grid-gap="35px 0"
					padding="0px 0px 0px 70px"
					lg-padding="0px 0px 0px 0"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							Can Vibrant Ventures host events outside of traditional venues?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Yes, our expertise extends to organizing events in a variety of settings, from outdoor spaces to unconventional venues, ensuring professional ambiance and logistics.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							What makes Vibrant Ventures different from other event planning services?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							Our passion for creating personalized, memorable experiences, combined with our commitment to excellence and attention to detail, sets us apart. We believe in crafting events that not only meet but exceed expectations, leaving lasting impressions.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px" margin="0px 0px 35px 0px">
						<Text margin="0px 0px 12px 0px" font="normal 500 22px/1.2 --fontFamily-sans" color="--darkL1">
							How do you handle unexpected changes or last-minute requests?
						</Text>
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							We are adaptable and resourceful, with the ability to effectively manage unexpected changes or last-minute requests, ensuring your event runs smoothly without any hiccups.
						</Text>
					</Box>
					<Box min-width="100px" min-height="100px">
						<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#555a5f">
							<Em>
								If your curiosity is not satisfied here, please contact us. Our team is happy to answer any additional questions you may have and guide you through the exciting process of bringing your event to life.
							</Em>
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65bb720efdcae00021dd9fe2"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});